<template>
  <v-card elevation="0">
    <v-form ref="form">
      <v-card-title>{{ selectedData.id == '' ? 'Tambah' : 'Ubah' }} Data</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="selectedData.name"
              label="Nama"
              placeholder="Nama"
              outlined
              hide-details="auto"
              required
              :rules="[v => !!v || 'Nama harus diisi!']"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" outlined small id="button-upload" @click="openFile('attach_image')">{{
              selectedData.image_filename != '' ? selectedData.image_filename : 'Upload'
            }}</v-btn>
            <input type="file" style="display: none" id="attach_image" accept="image/*" @change="changeFile($event, 'image', 'button-upload', 2048, 'filename_image')" />
            <input type="hidden" id="image" />
            <input type="hidden" id="filename_image" />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="selectedData.status"
              label="Status"
              placeholder="Status"
              outlined
              hide-details="auto"
              :items="statuses"
              item-text="name"
              dense
              item-value="value"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" small depressed @click="validate" :loading="loadingButton" :disabled="loadingButton"
              >Simpan</v-btn
            >
            <v-btn color="primary" small depressed outlined class="ml-3" @click="closeNav">Batal</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
import { seoURL } from '@/utils/index'
export default {
  props: ['closeNav', 'getData', 'setAlert', 'selectedData'],
  data() {
    return {
      statuses: [
        { name: 'Aktif', value: 1 },
        { name: 'Tidak Aktif', value: 0 },
      ],
      loadingButton: false,
    }
  },
  methods: {
    clearForm() {
      this.$refs.form.resetValidation()
    },
    validate() {
      if (this.$refs.form) {
        if (this.$refs.form.validate()) {
          this.changeFileVariable()
          this.submit()
        }
      }
    },
    submit() {
      this.loadingButton = true
      const token = localStorage.getItem('token')
      const button = document.getElementById('button-upload')
      button.innerHTML = 'Upload'
      const image = document.getElementById('image')
      const filename = document.getElementById('filename_image')
      image.value = ''
      filename.value = ''
      if (this.selectedData.id != '') {
        // edit
        axios
          .patch(
            `${process.env.VUE_APP_API_ENDPOINT}purchase-link/${this.selectedData.id}`,
            {
              name: this.selectedData.name,
              status: this.selectedData.status,
              image_filename: this.selectedData.image_filename,
              image: this.selectedData.image,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(res => {
            this.loadingButton = false
            if (res.status == 200) {
              this.setAlert({
                status: true,
                success: res.data.success,
                message: res.data.message,
              })
            } else {
              this.setAlert({
                status: true,
                success: false,
                message: res.statusText,
              })
            }
            this.getData()
            this.closeNav()
          })
          .catch(err => {
            this.loadingButton = false
            this.closeNav()
            const response = err.response
            const data = response.data
            this.setAlert({
              status: true,
              success: false,
              message: data.message,
            })
            if (data.message == 'Anda tidak diberikan akses!') {
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              this.$router.push({ name: 'login' })
            }
          })
      } else {
        axios
          .post(
            `${process.env.VUE_APP_API_ENDPOINT}purchase-link`,
            {
              name: this.selectedData.name,
              status: this.selectedData.status,
              image_filename: this.selectedData.image_filename,
              image: this.selectedData.image,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(res => {
            this.loadingButton = false
            if (res.status == 200) {
              this.setAlert({
                status: true,
                success: res.data.success,
                message: res.data.message,
              })
            } else {
              this.setAlert({
                status: true,
                success: false,
                message: res.statusText,
              })
            }
            this.getData()
            this.closeNav()
          })
          .catch(err => {
            this.loadingButton = false
            this.closeNav()
            const response = err.response
            const data = response.data
            this.setAlert({
              status: true,
              success: false,
              message: data.message,
            })
            if (data.message == 'Anda tidak diberikan akses!') {
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              this.$router.push({ name: 'login' })
            }
          })
      }
    },
    seoURL() {
      this.selectedData.seo_url = seoURL(this.selectedData.name)
    },
    openFile(id) {
      document.getElementById(id).click()
    },
    changeFile(event, id, buttonID, maxSize = 0, fileNameElementID) {
      const selectedElement = document.getElementById(id)
      const fileNameElement = document.getElementById(fileNameElementID)
      const button = document.getElementById(buttonID)
      const file = event.target.files[0]
      if (file) {
        const fsize = file.size
        const size = Math.round(fsize / 1024)

        if (maxSize > 0 && size > maxSize) {
          this.alert = {
            success: false,
            status: true,
            message: 'File terlalu besar!',
          }
          return
        }

        const reader = new FileReader()
        reader.onloadend = () => {
          const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
          selectedElement.value = base64String
        }
        reader.readAsDataURL(file)
        button.innerHTML = file.name.length > 15 ? file.name.slice(0, 13) + '...' : file.name
        fileNameElement.value = file.name
      }
    },
    changeFileVariable() {
      const image = document.getElementById('image')
      const filename = document.getElementById('filename_image')
      if (image.value != '' && filename.value != '') {
        this.selectedData.image = image.value
        this.selectedData.image_filename = filename.value
      }
    }
  },
}
</script>

<style>
</style>